<template>
    <v-data-table :headers="headers" :items="value" :mobile-breakpoint="0" hide-default-footer :items-per-page="-1">
        <template v-slot:item.first_name="{ item }">
            <v-edit-dialog :return-value.sync="item.first_name">
                <template v-slot:default>
                    <template v-if="!!item.first_name">{{ item.first_name }}</template>
                    <template v-else><v-icon color="red" left>error_outline</v-icon>¡Sin nombres!</template>
                </template>
                <template v-slot:input>    
                    <v-text-field class="my-2" hide-details single-line solo outlined flat dense v-model="item.first_name" label="Nombres" />
                </template>
            </v-edit-dialog>
        </template>
        <template v-slot:item.last_name="{ item }">
            <v-edit-dialog :return-value.sync="item.last_name">
                <template v-slot:default>
                    <template v-if="!!item.last_name">{{ item.last_name }}</template>
                    <template v-else><v-icon color="red" left>error_outline</v-icon>¡Sin apellidos!</template>
                </template>
                <template v-slot:input>    
                    <v-text-field class="my-2" hide-details single-line solo outlined flat dense v-model="item.last_name" label="Apellidos" />
                </template>
            </v-edit-dialog>
        </template>
        <template v-slot:item.email="{ item }">
            <v-edit-dialog :return-value.sync="item.email">
                <template v-slot:default>
                    <template v-if="!!item.email"><v-icon left>email</v-icon>{{ item.email }}</template>
                    <template v-else><v-icon color="red" left>error_outline</v-icon>¡Sin correo electrónico!</template>
                </template>
                <template v-slot:input>    
                    <v-text-field class="my-2" hide-details single-line solo outlined flat dense v-model="item.email" label="micorreo@empresa.com" />
                </template>
            </v-edit-dialog>
        </template>
         <template v-slot:item.empresa="{ item }">
            <v-edit-dialog :return-value.sync="item.empresa.nombre">
                <template v-slot:default>
                    <template v-if="!!item.empresa.nombre"><v-icon left></v-icon>{{ item.empresa.nombre }}</template>
                    <template v-else><v-icon color="red" left>error_outline</v-icon>¡Sin Empresa!</template>
                </template>
                <template v-slot:input>    
                    <v-text-field class="my-2" hide-details single-line solo outlined flat dense v-model="item.empresa.nombre" label="Empresa" />
                </template>
            </v-edit-dialog>
        </template>
         <template v-slot:item.gerencia="{ item }">
            <v-edit-dialog :return-value.sync="item.gerencia.nombre">
                <template v-slot:default>
                    <template v-if="!!item.gerencia.nombre"><v-icon left></v-icon>{{ item.gerencia.nombre }}</template>
                    <template v-else><v-icon color="red" left>error_outline</v-icon>¡Sin gerencia!</template>
                </template>
                <template v-slot:input>    
                    <v-text-field class="my-2" hide-details single-line solo outlined flat dense v-model="item.gerencia.nombre" label="Gerencia" />
                </template>
            </v-edit-dialog>
        </template>
        <!-- No borrar. sirve como ejemplo. -->
        <!-- <template v-slot:item.empresa="{ item }">
            <Selector dense 
                    v-model="item.empresa" 
                    :items="empresas" 
                    return-object
                    label="Empresa"
                    create-dialog-title="Crear nueva empresa" 
                    create-dialog-placeholder="Nombre de la Empresa"
                    item-text="nombre" />
        </template>
        <template v-slot:item.gerencia="{ item }">
            <Selector dense 
                    v-model="item.gerencia" 
                    :items="gerencias" 
                    return-object
                    label="Gerencia"
                    create-dialog-title="Crear nueva gerencia" 
                    create-dialog-placeholder="Nombre de la Gerencia"
                    item-text="nombre" />
        </template> -->
        <template v-slot:item.actions="{ item }">
            <v-btn icon @click="remove(item)">
                <v-icon>close</v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>

<script>
import Selector from '@/dash/components/Selector'

export default {
    props: ['value', 'empresas', 'gerencias'],
    components: {
        Selector
    },
    data: () => ({
        headers: [
            { value: "rut", text: "R.U.T", sortable: false, divider: true, width: '130px' },
            { value: "first_name", text: "Nombres", sortable: false },
            { value: "last_name", text: "Apellidos", sortable: false },
            { value: "email", text: "Correo electrónico", sortable: false, divider: true },
            { value: "empresa", text: "Empresa", sortable: false, width: '300px' },
            { value: "gerencia", text: "Gerencia", sortable: false, width: '300px', divider: true },
            { value: "actions", text: "", sortable: false, width: '32px' }
        ]
    }),
    methods: {
        remove(item) {
            this.value.splice(this.value.indexOf(item), 1);
            this.value = [...this.value];
        }
    }
}
</script>