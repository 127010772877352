<template>
    <v-form ref="form" id="form" style="width: 100%">
        <v-row>
            <v-col cols="12">
                <v-btn class="ma-1" large solo depressed color="secondary" @click="show_add_dialog">
                    <v-icon class="mr-2">group_add</v-icon>
                    <span class="subtitle-2">Añadir participantes</span>
                </v-btn>
                <v-btn class="ma-1" large solo depressed color="secondary" @click="show_import_dialog">
                    <v-icon class="mr-2">cloud_upload</v-icon>
                    <span class="subtitle-2">Importar participantes</span>
                </v-btn>
                <v-btn class="ma-1" large solo depressed color="green" dark @click="download_format">
                    <v-icon class="mr-2">get_app</v-icon>
                    <span class="subtitle-2">Descargar formato</span>
                </v-btn>
            </v-col>
            <v-col cols="12" md="4">
                <DashCard title="Información general" title-icon="info_outline">
                    <v-row class="ma-0">
                        <v-col cols="12">
                            <v-label>Nombre</v-label>
                            <v-text-field solo outlined dense flat hide-details v-model="item.nombre" :rules="[rules.required]" />
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-label>Inicio</v-label>
                            <DatePickerText v-model="item.fecha_inicio" flat dense hide-details :rules="[rules.required]" />
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-label>Termino</v-label>
                            <DatePickerText v-model="item.fecha_termino" flat dense hide-details :rules="[rules.required]" />
                        </v-col>
                        <v-col cols="12">
                            <v-label>Incidente ambiental asociado</v-label>
                            <IncidenteSelector v-model="item.ref_incidente" :datos="list_incidentes" />
                        </v-col>
                    </v-row>
                </DashCard>
            </v-col>
            <v-col cols="12" md="4">
                <DashCard title="Evaluador" title-icon="account_circle">
                    <v-label v-if="is_super">
                        Seleccione un evaluador previamente registrado, el cual podrá rendir las evaluaciones generales, e incluso las grupales.
                    </v-label>
                    <template v-if="!is_super">
                        <v-list no-gutters class="ma-0">
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon x-large color="primary">account_circle</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle>
                                        {{ item.evaluador.rut }}
                                    </v-list-item-subtitle>
                                    <v-list-item-title>
                                        {{ item.evaluador.first_name + ' ' + item.evaluador.last_name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ item.evaluador.empresa.nombre }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        {{ item.evaluador.gerencia.nombre }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </template>
                    <v-autocomplete v-else
                        clearable solo outlined flat dense hide-details 
                        prepend-inner-icon="account_circle" label="Seleccione un evaluador"
                        v-model="item.evaluador" 
                        :items="list_evaluadores" 
                        :rules="[rules.required]"
                        :item-text="(i) => `${i.first_name} ${i.last_name} (${i.rut})`"
                        return-object />
                </DashCard>
            </v-col>
            <v-col cols="12" md="4">
                <DashCard :title="`Cursos (${item.cursos.length})`" title-icon="class" body-class="pa-0">
                    <div class="px-4 py-2" v-if="is_super">
                        <v-label>
                            Añada/edite los cursos que podrán ver los participantes.
                        </v-label>
                    </div>
                    <v-divider />
                    <CursoListEditor v-model="item.cursos" :items="list_cursos" :disabled="!is_super" />
                </DashCard>
            </v-col>
            <v-col cols="12" md="12" v-if="!caducado">
                <DashCard :title="`Participantes (${item.participantes.length})`" title-icon="group" body-class="pa-0">
                    <div class="pa-4">
                        <v-label>
                            Los participantes agregados, tendrán un acceso limitado a los cursos seleccionados. <b>({{ item.fecha_inicio | moment("dddd LL") }} hasta {{ item.fecha_termino | moment("dddd LL") }})</b>.
                        </v-label>
                    </div>
                    <v-divider />
                    <ParticipanteMassEditor v-model="item.participantes" ref="participantes" :empresas="empresas" :gerencias="gerencias"/>
                </DashCard>
            </v-col>
            <v-col cols="12">
                <DashCard title="Consideraciones">
                    <span class="subtitle-1">
                        <ul>
                            <li>Al guardar cambios, puede ocurrir que <b>la lista de participantes se verá reducida en cantidad</b>, debido a que se omitirán participantes duplicados, en caso que los haya.</li>
                        </ul>
                    </span>
                    <div class="mt-2 text-right">
                        <v-btn depressed color="primary" class="subtitle-2" large @click="upload">
                            Guardar cambios
                        </v-btn>
                        <v-btn depressed color="primary" class="subtitle-2 ml-2" large exact :to="{ name: 'dashboard_groups_list' }">
                            Descartar
                        </v-btn>
                    </div>
                </DashCard>
            </v-col>
        </v-row>
        <DownloadDialog ref="down_dialog" />
        <DialogAddParticipante ref="add_dialog" :personas="list_participantes" @success="add_to_list" />
        <DialogImportParticipantes ref="import_dialog" @success="add_to_list" />
        <Message ref="success" @onClose="$router.push({ name: 'dashboard_groups_list' })" />
        <Message ref="error" />
    </v-form>
</template>

<script>
import DialogImportParticipantes from '@/educap/dialogs/DialogImportParticipantes'
import DialogAddParticipante from '@/educap/dialogs/DialogAddParticipante'
import DatePickerText from '@/shared/components/DatePickerText'
import CursoListEditor from '@/educap/components/CursoListEditor'
import ParticipanteMassEditor from '@/educap/components/ParticipanteMassEditor'
import IncidenteSelector from '@/educap/components/IncidenteSelector'
import DashCard from '@/shared/components/DashCard'
import Message from '@/shared/components/Message'
import DownloadDialog from '@/shared/components/DownloadDialog'
import { axiosHelper, messages, valid_required } from '@/helpers'

export default {
    components: {
        CursoListEditor,
        DownloadDialog,
        DialogAddParticipante,
        DialogImportParticipantes,
        ParticipanteMassEditor,
        IncidenteSelector,
        DatePickerText,
        DashCard,
        Message
    },
    data: () => ({
        item: {
            participantes: [],
            cursos: []
        },
        headers: [
            { value: "rut", text: "R.U.T" },
            { value: "first_name", text: "Nombres" },
            { value: "last_name", text: "Apellidos" },
            { value: "empresa", text: "Empresa" },
            { value: "gerencia", text: "Gerencia" },
            { value: "actions", text: "", sortable: false }
        ],
        gerencias: [],
        empresas: [],
        list_incidentes: [],
        list_evaluadores: [],
        list_participantes: [],
        list_cursos: [],
        rules: {
            required: valid_required
        }
    }),
    computed: {
        is_super() {
            return this.$parent.$parent.isin('supers');
        },
        caducado() {
            return this.$route.params.caducado;
        }
    },
    methods: {
        download_format() {
            this.$refs.down_dialog.show("edu/grupos/formato/");
        },
        show_add_dialog() {
            this.$refs.add_dialog.show();
        },
        show_import_dialog() {
            this.$refs.import_dialog.show();
        },
        add_to_list(items) {
            this.item.participantes = this.item.participantes.concat(items);
            this.$forceUpdate();
        },
        remove(item) {
            this.item.participantes.splice(this.item.participantes.indexOf(item), 1);
            this.item.participantes = [...this.item.participantes];
        },
        upload() {
            const edit = this.$route.meta.edit_mode;
            let item = { ...this.item };

            if(!this.$refs.form.validate()) {
                this.error({ data: { detail: messages.texts["error_check_fields"] } });
                return;
            }

            if(this.item.participantes.length < 1) {
                this.error({ data: { detail: "Debe haber al menos un participante." } });
                return;
            }

            if(this.item.cursos.length < 1) {
                this.error({ data: { detail: "Debe haber al menos un curso asignado." } });
                return;
            }

            if(this.item.participantes.filter(x => !!x.gerencia) < 1) {
                this.error({ data: { detail: "Todos los participantes deben tener datos de Gerencia perteneciente" } });
                return;
            }

            if(this.item.participantes.filter(x => !!x.empresa) < 1) {
                this.error({ data: { detail: "Todos los participantes deben tener datos de Empresa perteneciente" } });
                return;
            }
            
            if(!edit) {     
                console.log("!edit");   
                axiosHelper.post('edu/grupos/', item)
                        .then(response => this.success(response))
                        .catch(response => this.error(response));
            } else {
                axiosHelper.put('edu/grupos/' + this.$route.params.id + '/', this.item)
                        .then(response => this.success(response))
                        .catch(response => this.error(response));
            }
        },
        success(response) {
            const edit = this.$route.meta.edit_mode;
            this.$refs.success.show(messages.titles['success_generico'], messages.texts[edit ? 'success_upload_edit' : 'success_upload_new'], "success");
        },
        error(response) {
            var text = !!response.data ? response.data.detail : messages.texts['error_desconocido'];
            this.$refs.error.show(messages.titles['error_generico'], text, "error");
        }
    },
    mounted() {
        let title = "Crear/editar grupo";
        const edit = this.$route.meta.edit_mode;
        
        if(!edit) {
            title = "Crear nuevo grupo";
            axiosHelper.get('edu/grupos/crear/')
                    .then(response => {
                        this.gerencias = response.data.gerencias;
                        this.empresas = response.data.empresas;
                        this.list_incidentes = response.data.incidentes;
                        this.list_evaluadores = response.data.evaluadores;
                        this.list_participantes = response.data.participantes;
                        this.list_cursos = response.data.cursos;
                    });
        } else {
            axiosHelper.get('edu/grupos/' + this.$route.params.id + '/editor/')
                    .then(response => {
                        this.gerencias = response.data.gerencias;
                        this.empresas = response.data.empresas;
                        this.list_incidentes = response.data.incidentes;
                        this.list_evaluadores = response.data.evaluadores;
                        this.list_participantes = response.data.participantes;
                        this.list_cursos = response.data.cursos;
                        this.item = response.data.item;
                    });
            title = "Editar grupo";
        }

        this.$parent.$parent.set_title(title, "group_add");

    }
}
</script>