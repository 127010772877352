<template>
    <CustomDialog ref="dialog" title="Importar nuevos participantes" @accept="upload" @cancel="cancel">
        <v-form ref="form" id="form">
            <v-row>
                <v-col cols="12">
                    <v-label>
                        Suba un archivo Excel (.xlsx o .xls) o CSV (separado por ";") para importar nuevos participantes.<br />
                        Si dentro de la lista, ya existe un participante con el mismo R.U.T, se usarán los datos ya existentes en nuestros registros.
                    </v-label>
                    <v-divider class="my-2" />
                    <v-label>
                        <b>Nota importante:</b> Antes de cargar el archivo revise que este no contenga campos vacios.
                        De lo contrario su contenido no se cargará al sistema.
                    </v-label>
                </v-col>
                <v-col cols="12" v-if="!processing">
                    <v-label>Archivo</v-label>
                    <v-file-input :rules="[rules.required]" v-model="item.archivo" label="Archivos Excel (*.csv, *.xlsx, *.xls)" accept=".csv,.xlsx,.xls" solo outlined dense flat hide-details @change="set_name" />
                </v-col>
                <v-col cols="12" v-else>
                    <v-label>Subiendo {{ item.nombre }}</v-label>
                    <v-progress-linear :value="progress" striped rounded height="32" color="secondary">
                        <template v-slot="{ value }">
                            <strong>{{ Math.ceil(value) }}%</strong>
                        </template>
                    </v-progress-linear>
                </v-col>
            </v-row>
        </v-form>
        <Message ref="success" @onClose="close" />
    </CustomDialog>
</template>

<script>
import EmptyCard from '@/shared/components/EmptyCard'
import Message from '@/shared/components/Message'
import CustomDialog from '@/shared/components/CustomDialog'
import { axiosHelper, messages, valid_required } from '@/helpers'

export default {
    components: {
        CustomDialog,
        Message,
        EmptyCard
    },
    data: () => ({
        list: [],
        item: {},
        processing: false,
        progress: 0,
        rules: {
            required: valid_required
        }
    }),
    methods: {
        show(item) {
            this.$refs.dialog.show();
            this.$refs.dialog.persistent = false;
            this.$refs.dialog.actions_enabled = true;
        },
        set_name(file) {
            if(!file) return;
            this.item.nombre = file.name;
        },
        upload() {
            if(!this.$refs.form.validate()) {
                this.$refs.dialog.$refs.message.show(messages.titles["error_generico"], messages.texts["error_check_fields"], "error");
                return;
            }

            if(!this.item.archivo) {
                this.$refs.dialog.$refs.message.show(messages.titles["error_generico"], "Debe especificar un archivo.", "error");
                return;
            }

            this.processing = true;
            this.$refs.dialog.persistent = true;
            this.$refs.dialog.actions_enabled = false;

            var form_data = new FormData();
            form_data.append('archivo', this.item.archivo);
            let options = { onUploadProgress: progressEvent => this.progress = (progressEvent.loaded / progressEvent.total) * 100 };

            axiosHelper.post('edu/grupos/importar/', form_data, { 'content-type': 'multipart/form-data' }, options)
                .then(response => {
                    this.close(response.data);
                })
                .catch(error => {
                    this.processing = false;
                    this.$refs.dialog.persistent = false;
                    this.$refs.dialog.actions_enabled = true;
                    this.$refs.dialog.$refs.message.show(messages.titles["error_generico"], messages.texts["error_desconocido"], "error");
                });
        },
        close(data) {
            this.$refs.dialog.hide();
            this.$emit('success', data);
            this.processing = false;
        },
        cancel() {
            if(!!this.$refs.form) this.$refs.form.reset();
            this.item = {};
            this.processing = false;
            this.$refs.dialog.hide();
        }
    }
}
</script>